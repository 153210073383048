<template>
  <div>
    <div class="widgetContainer widgetContainer--full-screen widgetContainer--center welcome">
      <div>
        <BrandImg
          class="welcome__logo"
          @click="devToolOpenClickHandler"
          src="logos/Logo.svg"
          data-testid="logo"
          alt="logo" />
        <div class="welcome__header">
          <p data-testid="welcome-txt">
            {{ $t('welcome_title') }}
          </p>
          <span data-testid="welcome-desc" v-if="isTestModeEnabled == true">{{ welcomText }}</span>
        </div>
      </div>
      <ul class="welcome__switch-list" v-if="isTestModeEnabled == true">
        <li
          data-testid="test-mode"
          @click="login(currentMode === 'prod' ? 'prodtest' : mode)"
          class="test-mode">
          <p>
            {{ $t('prod_test') }}
          </p>
          <span class="icon-Arrow-small-right" />
        </li>
        <li
          data-testid="live-mode"
          @click="login('prod')"
          class="live-mode">
          <p>
            {{ $t('prod_live') }}
          </p>
          <span class="icon-Arrow-small-right" />
        </li>
      </ul>
      <el-button v-else type="primary" :style="{background: primaryColor}"
                 class="welcome__get-started el-button__brand brand"
                 @click="login(mode)">
        Get started
      </el-button>
    </div>
    <transition
      name="fade-2"
      mode="out-in"
      appear>
      <div
        class="wise-popup__container wise-popup__container--mini popup-env hello-popup"
        v-if="devToolOpenStatus">
        <div class="wise-popup">
          <section class="wise-popup__body">
            <el-form
              style="padding: 40px; display: flex; flex-direction: column"
              ref="envForm">
              <h4 style="margin-bottom: 40px">
                Change environment
              </h4>
              <el-form-item label="Environment">
                <el-select
                  :value="getActiveEnv"
                  style="width: 100%"
                  popper-class="dev-tools-select"
                  @change="envChangeEvent($event)">
                  <el-option
                    v-for="(option, key) in envOptions"
                    :value="key"
                    :key="option + key"
                    :label="option" />
                </el-select>
              </el-form-item>
              <el-button
                class="cancel-btn"
                @click="devToolOpenStatus = false">
                Close
              </el-button>
            </el-form>
          </section>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { setEnv, getBrandConfig, getBrandKey } from '@/utils/env';
import { mapGetters } from 'vuex';
import BrandImg from '@/components/BrandImg.vue';

export default {
  name: 'Welcome',
  components: {
    BrandImg
  },
  data: () => {
    return {
      clickCount: 0,
      devToolOpenStatus: false,
      isEnvChanged: false,
      mode: 'prodtest',
      envOptions: {
        qa: 'QA',
        development: 'Dev',
        staging: 'Staging',
        prodtest: 'Test mode',
        prod: 'Production'
      }
    };
  },
  created() {
    this.mode = this.getActiveEnv;
  },
  computed: {
    ...mapGetters(['getActiveEnv']),
    currentMode() {
      return process.env.VUE_APP_ENV;
    },
    isTestModeEnabled() {
      return (getBrandConfig('isTestModeEnabled', true));
    },
    primaryColor() {
      return (getBrandConfig('primaryColor', '#141414'));
    },
    welcomText(){
      const key = getBrandKey();
      return this.$t(key !== 'solid' ? (key+'_welcome_tagline') : 'welcome_desc')
    }
  },
  methods: {
    login(mode) {
      this.mode = mode;
      setEnv(this.mode);
      setTimeout(() => {
        window.analytics && window.analytics.track('getStarted');
        this.$router.push('/user/login');
      }, 0);
    },
    devToolOpenClickHandler() {
      if (
        process.env.VUE_APP_ENV !== 'prod' &&
        process.env.VUE_APP_ENV !== 'prodtest'
      ) {
        if (this.clickCount >= 5) {
          this.clickCount = 0;
          this.openDevTool();
        } else {
          this.clickCount++;
        }
      }
    },
    openDevTool() {
      this.devToolOpenStatus = true;
    },
    envChangeEvent(e) {
      setEnv(e);
      this.mode = e;
      this.devToolOpenStatus = false;
      this.isEnvChanged = true;
    }
  }
};
</script>
<style lang="scss">
.welcome {
  justify-content: space-between;

  .welcome__switch-list {
    li {
      border-color: var(--shifl-light-grey);
    }
  }

  #hello-sign-container {
    height: 70vh;
  }

  .welcome__logo {
    height: 40px;
    margin-top: 66px;
    width: auto;
  }

  &__header {
    text-align: center;

    p {
      font-size: 24px;
      line-height: 30px;
      padding-bottom: 7px;
    }

    span {
      color: rgba(#000, 0.6);
      font-size: 14px;
      line-height: 20px;
      padding-bottom: 32px;
      display: inline-block;
      padding: 0 25px 32px 25px;
    }
  }

  &__description {
    padding: 15px 0 15px 0;
  }

  &__switch-list {
    margin-top: 10px;
    background: white;
    border-radius: var(--radius);
    padding-bottom: 2px;

    li {
      position: relative;
      padding: 21px 16px 21px 48px;
      border: 1px solid #edeff1;
      border-radius: var(--radius);
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &:first-of-type {
        margin-bottom: 16px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
        border-radius: 50%;
        width: 16px;
        height: 16px;
      }

      &.test-mode {
        &:before {
          background-color: var(--orange2);
        }
      }

      &.live-mode {
        &:before {
          background-color: var(--color-green);
        }
      }

      p {
        font-size: 14px;
        line-height: 20px;
        color: var(--branding);
      }

      span {
        font-size: 12px;
      }
    }
  }

}

// Popup env
.popup-env {
  overflow: hidden;

  .wise-popup {
    margin: auto;
    width: 100%;
    max-width: 720px;
    min-height: 70vh;
    max-height: 90vh;
  }

  .cancel-btn {
    max-width: 100px;
    align-self: flex-end;
  }
}
</style>
