<template>
  <div class="legal_container">
    <legal-content />
  </div>
</template>
<script>
import LegalContent from '@/components/LegalContent';

export default {
  name: 'Legal',
  components: {LegalContent}
}
</script>
<style lang="scss" scoped>
.legal_container {
  padding: .5rem;
}
</style>
