<template>
  <div class="widgetContainer widgetContainer--center bg-white loginOtp">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="cancel" />
        <p class="title">
          2-Factor Authentication
        </p>
      </div>
    </div>
    <div class="widgetContainer__body">
      <el-form
        @keyup.enter.native="submitForm()"
        :model="model"
        :rules="rules"
        ref="user"
        @submit.prevent.native
        class="wise-form">
        <el-form-item prop="code" :label="$t('tfa_Title')">
          <MobileOtp v-model="model.code" :autofocus="true" />
        </el-form-item>        
        <div class="widgetContainer__footer--fixed border-0">
          <el-button            
            type="primary"
            @click="submitForm('user')"
            :loading="isLoading"            
            class="el-button__brand brand">
            {{ $t('next') }}
          </el-button>
        </div>
      </el-form>      
    </div>
  </div>
</template>
<script>
import { Auth } from '@/services/auth'
import MobileOtp from '@/components/MobileOtp';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import loginMixins from '../mixins/login';
import { getBrandConfig, getBrandEnv } from '@/utils/env';

export default {
  name: 'Login',
  mixins: [loginMixins],
  components: {
    MobileOtp
  },
  computed: {
    ...mapGetters('user', ['getAuthEmail', 'get_solid_auth_token'])
  },
  data() {
    return {
      isLoading: false,
      model: {
        code: ''
      },
      rules: {          
        code: [
          {
            required: true,
            message: 'Please input OTP',
            trigger: 'blur'
          },
          {            
            minimum: 6,
            message: 'Please input 6 digit',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions('user', ['login']),
    cancel() {

    },
    submitForm(formName) {             
      this.$refs[formName].validate((valid) => {          
        if (valid) {                              
          this.verifyOtp()
        }
      })
    },
    verifyOtp() {
      const loader = this.showLoader();
      this.isLoading = true
      Auth.verifyOtp({ email: this.getAuthEmail, code: this.model.code }).then(response => {
        if(this.get_solid_auth_token) {
          this.mobileLogin(this.get_solid_auth_token, loader)
        } else {
          this.$router.push({name: 'mobile.login'});
        }
      }).catch((e) => {                          
        this.apiErrorMessage(e);          
        this.isLoading = false
        loader.close();
      })  
    },
    mobileLogin({mobile}, loader) {
      this.login(mobile)
        .then(() => {
          this.$router.push('/user/loginOtp');
        }).finally(() => {
          this.isLoading = false
          loader.close();
        });
    },
  }
};
</script>

<style lang="scss">
.loginOtp {
  &__resend-text {
    display: flex;
    font-size: 14px;
  }
  &__resend-btn {
    color: var(--branding);
    cursor: pointer;
    text-decoration: underline;
    margin-left: auto;
    &.is-disabled {
      cursor: not-allowed;
      color: grey;
    }
  }
  &__resend-timer {
    color: var(--color-red);
  }
  .navigationHeader {
    min-height: 68px;
  }
}

</style>
