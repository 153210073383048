<template>
  <div class="widgetContainer widgetContainer--login-screen">
    <img src="@/assets/images/logo.66009768.svg" alt="">
    <div class="form px-12">
      <el-row>
        <el-col :span="24">
          <h2 class="title">
            Sign In to Shifl
          </h2>
          <el-form
            :model="user"
            ref="user"
            class="demo-ruleForm"
            @submit.native.prevent @keyup.enter.native="submitForm('user')">
            <el-form-item prop="email" label="EMAIL ADDRESS" :rules="rules.email">
              <el-input v-model="user.email" placeholder="e.g. abcdefghij@email.com" />
            </el-form-item>
            <el-form-item prop="password" label="PASSWORD" :rules="rules.password">
              <el-input v-model="user.password" show-password placeholder="Type your password" />
            </el-form-item>
            <el-row style="margin: 40px 0 40px;">
              <el-col :gutter="20" :span="12" class="grid-content">
                <el-checkbox v-model="user.remember_me">
                  Remember me
                </el-checkbox>
              </el-col>
              <el-col :span="12">
                <router-link to="/user/forget-password">
                  Forget Password?
                </router-link>
              </el-col>
            </el-row>
            <el-button
              class="btn-button"
              type="primary"
              :loading="isLoading"
              @click="submitForm('user')">
              Sign In
            </el-button>
          </el-form>
          <label v-if="isError" class="error-message">
            {{ errorMessage }}
          </label>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.form {
  padding: 0 20px 30px;

  .btn-button {
    display: block;
    width: 100%;
    background: #0171a1;
    border: #0171a1;
  }

  .error-message {
    padding: 20px 10px 0px;
    text-align: center;
    color: #F56C6C;
    font-size: 12px;
    display: block;
  }
}
</style>
<script>
import {Auth} from '@/services/auth'
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  data() {
    return {
      user: {
        email: '',
        password: '',
        remember_me: false,
      },
      isError: false,
      errorMessage: '',
      isLoading: false,
      rules: {
        email: [
          {required: true, message: 'Please input email address', trigger: 'blur'},
          {type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change']}
        ],
        password: [
          {required: true, message: 'Please input password', trigger: 'blur'},
        ],
        checkbox: []
      }
    }
  },
  computed: {
    ...mapGetters('user', ['getAccessToken'])
  },
  mounted() {
    console.log(this.getAccessToken)
  },
  methods: {
    ...mapActions('user', ['login']),
    ...mapMutations('user', ['setAuthToken', 'setPersonDetails']),
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {          
        if (valid) {                              
          this.authenticate()
        }
      });
    },
    async authenticate() {
      this.isLoading = true
      const loader = this.showLoader();
      try {
        const response = await Auth.login({...this.user})
        const { token, person } = response.data.data
        this.setAuthToken(token);
        const pilotResult = await this.$serverRequest.get('/v1/pilot')
        this.$store.commit('user/setPilotAccounts',pilotResult.data);
        if (person) return this.mobileLogin(person);
        await this.$router.push({name: 'mobile.login'});
      }catch (e) {
        this.isError = true
        if ([422,401].includes(e.response.status)){
          this.errorMessage = 'Invalid username or password'
          this.apiErrorMessage(this.errorMessage);
        }else {
          this.apiErrorMessage(e);
        }
      } finally {
        this.isLoading = false
        loader.close();
      }
    },
    ...mapMutations('user',['setUserDetails']),
    mobileLogin({mobile}) {
      this.setUserDetails({phone: mobile})
      this.$router.push('/user/loginOtp');
    },
    getUser(loader) {
      Auth.details().then(response => {
        console.log(response.data.success)
        this.setPersonDetails(response.data.success)
        this.$router.push({name: 'mobile.login'});
      }).catch((e) => {          
        this.isError = true
        this.errorMessage = this.getErrorMessage(e)
        this.apiErrorMessage(e);
      })
        .finally(() => {
          this.isLoading = false
          loader.close();
        });
    }
          
  }
}
</script>